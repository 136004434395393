import React, { useState } from 'react';
import {Box,Grid,ListItem,ListItemIcon,ListItemText,IconButton,List,Divider,Card,Drawer,Typography,Dialog,DialogContent,DialogTitle,DialogActions,Button} from
 '@material-ui/core';
 import {orange} from '@material-ui/core/colors';
 
import {makeStyles} from '@material-ui/core/styles';
import NotificationsIcon from '@material-ui/icons/Notifications';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import {useDispatch, useSelector} from 'react-redux';
import {CallNotifications} from '../../../../redux/actions';
import {MRD_OPEN_NOTIFI,MRD_NOTIFI_TEXT} from '../../../../shared/constants/ActionTypes';
import jwtAxios from '../../../../@crema/services/auth/jwt-auth/jwt-api';
import {useAuthUser} from '../../../../@crema/utility/AppHooks';
import {useHistory} from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  drawerr: {
    width: '20%',
  },
  btnblue: {
    background: '#2e4167',
    color: '#ffffff',
    textTransform: 'capitalize',
    '&:hover': {
      background: '#2e4167',
      color: '#ffffff',
    },
  },
  btnred: {
    background: '#ff7252',
    color: '#ffffff',
    textTransform: 'capitalize',
    '&:hover': {
      background: '#ff7252',
      color: '#ffffff',
    },
  },
  clockicon: {
    minWidth: '32px',
    color: '#ffff',
  },
  closeBtnColor: {
    marginTop: '9px',
    color: '#ffff',
    background: 'red',
    borderRadius: '50px',
  },
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  button1: {
    textTransform: 'capitalize',
  },
  tabItem: {
    '& .MuiBadge-anchorOriginTopRightRectangle':{
      right:'-10px'
    },
    textTransform: 'capitalize',
    [theme.breakpoints.up('xs')]: {
      fontSize: '12px',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '12px',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '0.875rem',
    },
    // [theme.breakpoints.down('md')]: {
    //  display : 'none'
    // },
  },
  tabbgclr: {
    color: '#000',
    backgroundColor: '#fff',
  },
  hideTab: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  appFrame: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  cardStyle: {
    display: 'flex',
    alignItems: 'center',
    padding: '6px',
    marginBottom: '10px',
    boxShadow: 2,
    transition: '0.3s',
    //  borderRadius: "10px",
    '&:hover': {boxShadow: 6, transform: 'scale(1.02)', zIndex: '99999'},
  },
  appBar: {
    position: 'absolute',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  OCRData: {
    width: '100%',
    '& .MuiOutlinedInput-inputMultiline': {
      fontSize: 12,
    },
    '& .MuiOutlinedInput-multiline': {
      padding: '10.5px 7px',
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: 'none',
    },
  },
  title: {
    fontSize: 14,
    fontWeight: '500',
  },

  hide: {
    display: 'none',
  },
  drawerPaper: {
    position: 'relative',
    height: '100%',
    width: drawerWidth,
    [theme.breakpoints.up('xs')]: {
      display: 'none!important',
    },
    [theme.breakpoints.up('sm')]: {
      display: 'none!important',
    },
    [theme.breakpoints.up('md')]: {
      display: 'block!important',
    },
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
  content: {
    flexGrow: 1,

    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },

  zoomText: {
    fontSize: 11,
    color: '#000',
    paddingLeft: 3,
  },
  zoomIcons: {
    width: 15,
    height: 15,
  },
  Prescription: {
    margin: 0,
    position: 'relative',
    borderRadius: 4,
    border: ['1px solid #afa2a2a6'],
  },
  prescriptionHead: {
    padding: 10,
    marginTop: 4,
    marginBottom: 4,
  },
  Zoominicon: {
    alignItems: 'center',
    textAlign: 'left',
    padding: 2,
    display: 'flex',
  },
  PanToolIcon: {
    alignItems: 'center',
    display: 'flex',
    textAlign: 'right',
    padding: 2,
  },
  imageNewTab: {
    position: 'absolute',
    right: 5,
    top: 12,
    padding: 3,
  },
  OptNotes: {
    '& .MuiInputBase-input {': {
      fontSize: 13,
    },

    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(12),
    flexBasis: '23.33%',
    flexShrink: 0,
  },
  logo: {
    height: 22,
    marginRight: 10,
  },
  displayLinebreak: {
    whiteSpace: 'break-spaces',
  },
  ocrtextstyle: {
    color: 'gray',
    height: '400px',
    overflowY: 'scroll',
    width: '100%',
  },
  messagestyle: {
    background: '#185386',
    borderRadius: '15px',
    padding: '3%',
    color: 'white',
    margin: '2% 0',
  },
  pad3: {
    padding: '3%!important',
  },
  errortab: {
    color: 'red',
    fontWeight: 'bold',
  },
  customizerOption: {
    position: 'absolute',
    right: 0,
    top: 125,
    zIndex: 1110,
  },
  customizerButton: {
    borderRadius: '30px 0 0 30px',
    backgroundColor: orange[500],
    '&:hover': {
      backgroundColor: orange[700],
    },
    '& button': {
      borderRadius: '30px 0 0 30px',

      '&:focus': {
        borderRadius: '30px 0 0 30px',
      },
    },
  },
  countBadge: {
    background: '#49bd65',
    color: 'white',
    padding: '4px',
    borderRadius: '3px',
  },
  Prescription: {
    '& .carousel-root': {
      '& .carousel.carousel-slider .control-arrow': {
        // background: 'transparent !important',
        height: '31px',
        background: '#4e4e4e !important',
        borderRadius: '50%',
        textAlign: 'center',
        verticalAlign: 'middle',
        // marginTop: '50%',
      },
      '& .carousel.carousel .control-next.control-arrow:before': {
        borderLeft: '10px solid #fff',
        marginBottom: '6px',
        marginRight: '3px',
      },
      '& .carousel.carousel .control-prev.control-arrow:before': {
        borderLeft: '0px solid #fff',
        marginBottom: '6px',
        marginRight: '9px',
      },
      '& .carousel .slide img': {
        maxHeight: '500px !important',
        objectFit: 'cover !important',
        width: '100%',
        justifyContent: 'center',
      },
      '& .carousel *': {
        margin: 'auto !important',
      },
      '& .carousel .carousel-status': {
        position: 'absolute',
        top: '2px',
        right: '2px',
        padding: '4px',
        fontSize: '10px',
        background: '#2e4167',
        borderRadius: '50px',
        textAlign: 'center',
        width: '15%',
      },
    },
  },
}));
const drawerWidth = 280;

const CustomNotification = (props)=>{
    const classes = useStyles();
    const dispatch = useDispatch();
      const history = useHistory();
     const user = useAuthUser();
     const [popup,setpopup]=useState(false)
     const [tempval,setTempVal]=useState('')
    const cardOpen = useSelector(({dashboard}) => dashboard.mrdOpenNotifi);
    const mt_notifications = useSelector(
        ({dashboard}) => dashboard.MTnotifications,
      );
     const toggleDrawer = () => {
        dispatch({
          type: MRD_OPEN_NOTIFI,
          payload: !cardOpen,
        });
      };
      async function closeNotification(item) {
        if (item?.mrdId) {
          await changeSts(item,'clear');
        }
      }
      async function pickNotificaiton(item) {
        let currentLocation = window.location.href;
        if(currentLocation?.includes('/dichargecellProcess')){
          setTempVal(item)
          setpopup(true)
        }
        else{
          if (item?.mrdId) {
            let text = item?.patientName
              ? item?.patientName
              : item?.opId
              ? item?.opId
              : '';
              history.push('/dischargeCell')
            if (item?.messageType == 4 && text) {
              dispatch({
                  type: MRD_NOTIFI_TEXT,
                  payload: {sts:'complete',searchText:text},
                });
            }
            if (item?.messageType == 5 && text) {
              dispatch({
                  type: MRD_NOTIFI_TEXT,
                  payload: {sts:'pend',searchText:text},
                });
            }
            await changeSts(item);
          }
        }
        
      }
       async function changeSts(rowData,tag) {
          (async () => {
            const body = {
              mrdId: rowData?.mrdId,
            };
            await jwtAxios
              .post('v1/disChargeRoutes/changeNotificationSts', body)
              .then((data) => {
                if (data.status === 200) {
                  if(tag  !='clear'){
                    dispatch({
                      type: MRD_OPEN_NOTIFI,
                      payload: false,
                    });
                  }
                 
                  let pushdata = {
                    clientId: user.clientid || 0,
                    credentialId: user.credentialid || 0,
                    userType:'MT'
                  };
                  dispatch(CallNotifications(pushdata));
                  return;
                }
              });
          })();
        }
        async function proceedaction() {
          let item=tempval
           let text = item?.patientName
            ? item?.patientName
            : item?.opId
            ? item?.opId
            : '';
            history.push('/dischargeCell')
          if (item?.messageType == 4 && text) {
            dispatch({
                type: MRD_NOTIFI_TEXT,
                payload: {sts:'complete',searchText:text},
              });
          }
          if (item?.messageType == 5 && text) {
            dispatch({
                type: MRD_NOTIFI_TEXT,
                payload: {sts:'pend',searchText:text},
              });
          }
          await changeSts(item);
          setTempVal('')
        }
    const list = () => (
        <Box sx={{width: '300px'}} role='presentation'>
          <Grid container style={{backgroundColor: '#2e4167'}}>
            <Grid xs={10}>
              <ListItem key={'Notifications'}>
                <ListItemIcon className={classes.clockicon}>
                  <NotificationsIcon style={{color: '#ffff'}} />
                </ListItemIcon>
                <ListItemText
                  primary={'Notifications'}
                  primaryTypographyProps={{
                    style: {fontWeight: 'bold', color: '#fff'},
                  }}
                />
              </ListItem>
            </Grid>
            <Grid xs={2}>
              <IconButton
                onClick={() => toggleDrawer()}
                style={{padding: '0', paddingLeft: '10px'}}>
                <HighlightOffIcon className={classes.closeBtnColor} />
              </IconButton>
            </Grid>
          </Grid>
    
          <Divider />
          {mt_notifications.length > 0 ? (
            <List style={{overflow: 'auto', width: '98%', paddingLeft: '2%',height:'92vh',overflowY:'auto'}}>
              {mt_notifications.map((text, index) => (
                <Card
                  key={index}
                  className={classes.cardStyle}
                  style={{
                    background: text?.messageType == 4 ? '#D0F0C0' : '#F0E68C',
                    cursor: 'pointer',
                  }}
                  variant='outlined'>
                  <Grid container spacing={4}>
                    {/* Icon Section */}
    
                    {/* Message Section */}
                    <Grid item xs={11} onClick={() => pickNotificaiton(text)}>
                      <Typography
                        style={{
                          color: '#333',
                          fontSize: '13px',
                          //  fontWeight: "bold",
                          //  marginLeft: "8px",
                        }}>
                        {text.message}
                      </Typography>
                    </Grid>
    
                    {/* Close Button */}
                    <Grid
                      item
                      xs={1}
                      style={{display: 'flex', justifyContent: 'flex-end'}}>
                      <IconButton
                        size='small'
                        onClick={() => closeNotification(text)}
                        style={{color: '#888', '&:hover': {color: '#d32f2f'}}}>
                        <HighlightOffIcon fontSize='small' />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Card>
              ))}
            </List>
          ) : (
            <Grid container>
              <Grid item={true} xs={12} sm={12} style={{padding: '28%'}}>
                <p>No records found</p>
              </Grid>
            </Grid>
          )}
        </Box>
      );
    return (<>
    {cardOpen ? (
            <Drawer
              transitionDuration={{enter: 500, exit: 1000}}
              anchor='right'
              open={cardOpen}
              width='20%'
              onClose={() => toggleDrawer()}
              className={classes.drawerr}>
              {list()}
            </Drawer>
          ) : null}
            <Dialog open={popup} className={classes.dialogStyle} disableBackdropClick>
                  <DialogTitle id='customized-dialog-title'>Confirmation</DialogTitle>
                  <DialogContent>
                    <Typography
                      gutterBottom
                      component='h4'
                      style={{fontWeight: 600, color: '#000'}}>
                      This action will take you to the dashboard, and your current work won’t be saved. Are you sure you want to continue?
                    </Typography>
                  </DialogContent>
                  <DialogActions className={classes.dialogactionsclass}>
                    <Button onClick={() => setpopup(false)} className={classes.btnred}>
                      No
                    </Button>
                    <Button onClick={() => {
                      setpopup(false)
                      proceedaction()}} className={classes.btnblue}>
                      Yes
                    </Button>
                  </DialogActions>
                </Dialog>
    </>)
}

export default CustomNotification;