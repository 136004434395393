import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  UPDATE_AUTH_USER,
} from '../../shared/constants/ActionTypes';
import {Auth} from 'aws-amplify';
import {AuthType} from '../../shared/constants/AppEnums';

const getUserObject = (user) => {
  return {
    authType: AuthType.AWS_COGNITO,
    role: user.role,
    uid: user.username,
    displayName: user.attributes.name,
    email: user.attributes.email,
    photoURL: user.photoURL,
    token: user.signInUserSession.accessToken.jwtToken,
  };
};

export const onCognitoUserSignOut = () => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    Auth.signOut().then(() => {
      dispatch({type: FETCH_SUCCESS});
      dispatch({type: UPDATE_AUTH_USER, payload: null});
    });
  };
};

export const onGetLoggedInCognitoUser = () => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    Auth.currentAuthenticatedUser()
      .then((user) => {
        if (user) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: UPDATE_AUTH_USER,
            payload: getUserObject(user),
          });
        } else {
          dispatch({type: FETCH_ERROR, payload: user.error});
        }
      })
      .catch(function (error) {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};
