import React from 'react';
import {authRole} from '../../shared/constants/AppConst';

export const MTConfigs = [
  {
    auth: authRole.userMT,
    routes: [
      {
        path: '/dischargeCell',
        component: React.lazy(() => import('./SimpleTabs')),
      },
    ],
  },
  {
    auth: authRole.userMT,
    routes: [
      {
        path: '/completeList',
        component: React.lazy(() => import('./CompleteList')),
      },
    ],
  },
  {
    auth: authRole.userMT,
    routes: [
      {
        path: '/dichargecellProcess',
        component: React.lazy(() => import('./MTCorrectionpage')),
      },
    ],
  },
];
