import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
// import MenuItem from '@material-ui/core/MenuItem';
// import Menu from '@material-ui/core/Menu';
// import LanguageSwitcher from '../../LanguageSwitcher';
import {toggleNavCollapsed} from '../../../../redux/actions';
import {useDispatch} from 'react-redux';
import Hidden from '@material-ui/core/Hidden';
import HorizontalNav from '../../Navigation/HorizontalNav';
import Box from '@material-ui/core/Box';
import useStyles from './AppHeader.style';
import CustomNotification from './CustomNotification'
// import HeaderMessages from '../../HeaderMessages';
import Notifications from '../../Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import HorUserInfo from '../HorUserInfo';
import {useAuthUser} from '../../../../@crema/utility/AppHooks';
import AppLogo from '../../../../shared/components/AppLogo';
import {Typography} from '@material-ui/core';
import {useMediaQuery, useTheme} from '@material-ui/core';

const AppHeader = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const user = useAuthUser();
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  // function handleMobileMenuClose() {
  //   setMobileMoreAnchorEl(null);
  // }

  function handleMobileMenuOpen(event) {
    setMobileMoreAnchorEl(event.currentTarget);
  }

  const mobileMenuId = 'primary-search-account-menu-mobile';
  // const renderMobileMenu = (
  //   <Menu
  //     anchorEl={mobileMoreAnchorEl}
  //     anchorOrigin={{vertical: 'top', horizontal: 'right'}}
  //     id={mobileMenuId}
  //     keepMounted
  //     transformOrigin={{vertical: 'top', horizontal: 'right'}}
  //     open={isMobileMenuOpen}
  //     onClose={handleMobileMenuClose}>
  //     <MenuItem className={classes.menuItemRoot}>
  //       <HeaderMessages />
  //     </MenuItem>
  //     <MenuItem className={classes.menuItemRoot}>
  //       <Notifications />
  //     </MenuItem>
  //     <LanguageSwitcher />
  //   </Menu>
  // );

  return (
    <>
      <AppBar position='relative' style={{zIndex: 999, top: 0}}>
        {/* <NotificationBar /> */}
        <Toolbar className={classes.headerMain}>
          <Box className={classes.headerContainer}>
            <Box className={classes.headerMainFlex}>
              {/* <Hidden lgUp>
                <IconButton
                  edge='start'
                  className={classes.menuButton}
                  color='inherit'
                  aria-label='open drawer'
                  onClick={() => dispatch(toggleNavCollapsed())}>
                  <MenuIcon className={classes.menuIconRoot} />
                </IconButton>
              </Hidden> */}

              <AppLogo style={{width: '10%'}} />
              {isMobile ? (
                <Box className={classes.headerMainFlex} style={{width: '85%'}}>
                  <Typography
                    style={{
                      color: 'black',
                      textAlign: 'center',
                      fontWeight: 'bold',
                      fontSize: '12px',
                    }}>
                    {user.clientName} {'-' + user.userCategory}
                  </Typography>
                </Box>
              ) : (
                <Box className={classes.headerMainFlex} style={{width: '70%'}}>
                  <Typography
                    style={{
                      color: 'black',
                      textAlign: 'center',
                      fontWeight: 'bold',
                      fontSize: '20px',
                    }}>
                    {user.clientName}
                  </Typography>
                </Box>
              )}

              <HorUserInfo bgType='white' style={{width: '20%'}} />
              <CustomNotification/>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      {/* <Hidden mdDown> */}
      <AppBar style={{zIndex: 999, position: 'sticky', top: 0}}>
        <Box className={classes.headerNav}>
          <Box className={classes.headerContainer}>
            <HorizontalNav />
          </Box>
        </Box>
      </AppBar>
    </>
  );
};
export default AppHeader;
