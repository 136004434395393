/************
 * Developed by : Shiva Software Solutions
 * Date : 14-06-2024
 * Descriptions : Route for lab assist page
 ************/
import React from 'react';
import {authRole} from '../../shared/constants/AppConst';

export const radiologyConfigs = [
  {
    auth: authRole.radiologyUser,
    routes: [
      {
        path: '/radioAssist',
        component: React.lazy(() =>
          import('../radiologyPage/radioAssist/PrescriptionsPage'),
        ),
      },
    ],
  },
  {
    auth: authRole.radiologyUser,
    routes: [
      {
        path: '/radioAssistComplete',
        component: React.lazy(() =>
          import('../radiologyPage/radioAssist/DispensedListPage'),
        ),
      },
    ],
  },
];
