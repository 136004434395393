import React,{useEffect} from 'react';
import HorizontalGroup from './HorizontalGroup';
import HorizontalCollapse from './HorizontalCollapse';
import HorizontalItem from './HorizontalItem';
import HorizontalSingle from './HorizontalSingle';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import {useSelector} from 'react-redux';
import {authRole} from '../../../../shared/constants/AppConst';
import routesConfig from '../../../../modules/routesConfig';
import mobileroutesConfig from '../../../../modules/mobilerouteconfig';
import Box from '@material-ui/core/Box';
import { useMediaQuery,useTheme } from '@material-ui/core';

const HorizontalNav = () => {
  const LoginUser = useSelector(({auth}) => auth.user);
  const [routeConfigList, setRouteConfigList] = React.useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  useEffect(()=>{
    if(LoginUser != null){
      let routingConfig = routesConfig;
      if(LoginUser?.billing){
        routingConfig=routesConfig.map(item => 
          item.id =='Prescriptions' ? {...item,children:[...item.children, {
            id: 'billing',
            title: 'billing',
            messageId: 'sidebar.billing',
            type: 'item',
            auth: authRole.dashboardRoles,
            url: '/billing',
            checkauth: true,
          }]}:item
        )
        
      }
      if(LoginUser?.vaccineReminder || LoginUser?.mrdOption){
        let childen=[]
        if(LoginUser?.vaccineReminder){
          childen.push( {
            id: 'Vaccination Report',
            title: 'Vaccination Report',
            messageId: 'sidebar.vaccine',
            type: 'item',
            auth: authRole.dashboardRoles,
            url: '/vaccineReport',
            checkauth: true,
          })
        }
        if(LoginUser?.mrdOption){
          childen.push( {
            id: 'mrd',
            title: 'mrd',
            messageId: 'sidebar.mrd',
            type: 'item',
            auth: authRole.dashboardRoles,
            url: '/mrdReport',
            checkauth: true,
          })
          // childen.push({
          //   id: 'mrd&Lab',
          //   title: 'mrd&lab',
          //   messageId: 'sidebar.mrdlab',
          //   type: 'item',
          //   url: '/mrdLabReport',
          //   checkauth: true,
          // })
        }
        routingConfig.push( {
          id: 'Reports',
          title: 'Reports',
          messageId: 'sidebar.reports',
          type: 'group',
          auth: authRole.dashboardRoles,
          icon: 'receipt_long',
          children:childen
        })
        setRouteConfigList(routingConfig)
      }
      else{
        setRouteConfigList(routingConfig)
      }
    }
  },[LoginUser])
  return (
    <List className='navbarNav'>
       {!isMobile &&
        routeConfigList.map((item) => (
          <React.Fragment key={item.id}>
            {item.type === 'single' && (item?.auth?.includes(LoginUser.roleId) ) && item?.hide != true && (
              <HorizontalSingle item={item} nestedLevel={0} />
            )}

            {item.type === 'group' && (item?.auth?.includes(LoginUser.roleId)) && (
              <HorizontalGroup item={item} nestedLevel={0} />
            )}

            {item.type === 'collapse' && (
              <HorizontalCollapse item={item} nestedLevel={0} />
            )}

            {item.type === 'item' && (
              <HorizontalItem item={item} nestedLevel={0} />
            )}

            {item.type === 'divider' && (
              <Box my={5} clone>
                <Divider />
              </Box>
            )}
          </React.Fragment>
        ))}

      {isMobile &&
        mobileroutesConfig.map((item) => (
          <React.Fragment key={item.id}>
            {item.type === 'single' && item?.auth?.includes(LoginUser.roleId) && (
              <HorizontalSingle item={item} nestedLevel={0} />
            )}

            {item.type === 'group' && item?.auth?.includes(LoginUser.roleId) && (
              <HorizontalGroup item={item} nestedLevel={0} />
            )}

            {item.type === 'collapse' && (
              <HorizontalCollapse item={item} nestedLevel={0} />
            )}

            {item.type === 'item' && (
              <HorizontalItem item={item} nestedLevel={0} />
            )}

            {item.type === 'divider' && (
              <Box my={5} clone>
                <Divider />
              </Box>
            )}
          </React.Fragment>
        ))}
     
    </List>
  );
};

export default HorizontalNav;
