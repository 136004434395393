/************
 * Developed by : Shiva Software Solutions
 * Date : 15-06-2021
 * Descriptions : Route navigation for inner modules
 * Dependicies : To use @material-ui/core
 ************/

import {authRole} from '../shared/constants/AppConst';
// const localRoleId = localStorage.getItem('roleId');

const mobileroutesConfig = [
  {
    id: 'dashbaord',
    title: 'Dashboard',
    messageId: 'sidebar.dashboard',
    type: 'single',
    icon: 'dashboard',
    auth: authRole.dashboardRoles,
    url: '/dashboard',
  },
  {
    id: 'camDashboard',
    title: 'camDashboard',
    messageId: 'sidebar.camDash',
    type: 'single',
    icon: 'camera',
    auth: authRole.camDashRoles,
    url: '/cameraDashboard',
  },
  {
    id: 'searchpatientphar',
    title: 'searchpatientphar',
    messageId: 'sidebar.searchPat',
    type: 'single',
    icon: 'manage_search',
    auth: authRole.pharmacyRoles,
    url: '/pharmacy',
  },
  {
    id: 'dispensedList',
    title: 'dispensedList',
    messageId: 'sidebar.disList',
    type: 'single',
    icon: 'domain_verification',
    auth: authRole.pharmacyRoles,
    url: '/pharmacydispenced',
  },
  {
    id: 'itadmin',
    title: 'itadmin',
    messageId: 'sidebar.itadmin',
    type: 'single',
    icon: 'document_scanner',
    auth: authRole.itAdminRoles,
    url: '/ITAdminpage',
  },
  {
    id: 'searchpatientlab',
    title: 'searchpatientlab',
    messageId: 'sidebar.searchPat',
    type: 'single',
    icon: 'manage_search',
    auth: authRole.labRoles,
    url: '/labAssist',
  },
  {
    id: 'completedListlab',
    title: 'completedListlab',
    messageId: 'sidebar.comList',
    type: 'single',
    icon: 'domain_verification',
    auth: authRole.labRoles,
    url: '/labAssistComplete',
  },
  {
    id: 'searchpatient',
    title: 'searchpatient',
    messageId: 'sidebar.searchPat',
    type: 'single',
    icon: 'manage_search',
    auth: authRole.radiologyRoles,
    url: '/radioAssist',
  },
  {
    id: 'completedListrad',
    title: 'completedListrad',
    messageId: 'sidebar.comList',
    type: 'single',
    icon: 'domain_verification',
    auth: authRole.radiologyRoles,
    url: '/radioAssistComplete',
  },
  {
    id: 'pendingRequest',
    title: 'pendingRequest',
    messageId: 'sidebar.pendReq',
    type: 'single',
    icon: 'manage_search',
    auth: authRole.itadminRoles,
    url: '/ITAdminpage',
  },
  {
    id: 'completedList',
    title: 'completedList',
    messageId: 'sidebar.comReq',
    type: 'single',
    icon: 'domain_verification',
    auth: authRole.itadminRoles,
    url: '/ITAdminCompleted',
  },
];
export default mobileroutesConfig;
