/************
 * Developed by : Shiva Software Solutions
 * Date : 29-11-2024
 * Descriptions :MRD User Reducers
 ************/
import {
  MRD_LABRE_EXCEL,
  MRD_LABRE_LIST,
  MRD_LABRE_COUNT,
  MRD_PAGE_COUNT,
} from '../../shared/constants/ActionTypes';
const initialState = {
  mrdLabReExcel: [],
  mrdLabReList: [],
  medLabReCount: 0,
};
const mrdUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case MRD_LABRE_EXCEL:
      return {
        ...state,
        mrdLabReExcel: action.payload,
      };
    case MRD_LABRE_LIST:
      return {
        ...state,
        mrdLabReList: action.payload,
      };
    case MRD_LABRE_COUNT:
      return {
        ...state,
        medLabReCount: action.payload,
      };
    case MRD_PAGE_COUNT:
      return {
        ...state,
        pageCount: action.payload,
      };
    default:
      return state;
  }
};
export default mrdUserReducer;
