/************
 * Developed by : Shiva Software Solutions
 * Date : 15-06-2021
 * Descriptions : Route navigation for inner modules
 * Dependicies : To use @material-ui/core
 ************/

import {authRole} from '../shared/constants/AppConst';
// const localRoleId = localStorage.getItem('roleId');

const routesConfig = [
  {
    id: 'dashbaord',
    title: 'Dashboard',
    messageId: 'sidebar.dashboard',
    type: 'single',
    icon: 'dashboard',
    auth: authRole.dashboardRoles,
    url: '/dashboard',
  },
  {
    id: 'myDoctors',
    title: 'My Doctors',
    messageId: 'sidebar.myDoctor',
    type: 'single',
    icon: 'local_hospital',
    auth: authRole.dashboardRoles,
    url: '/myDoctors',
  },
  {
    id: 'camDashboard',
    title: 'camDashboard',
    messageId: 'sidebar.camDash',
    type: 'single',
    icon: 'camera',
    auth: authRole.camDashRoles,
    url: '/cameraDashboard',
  },
  {
    id: 'Prescriptions',
    title: 'Prescriptions',
    messageId: 'sidebar.myprescription',
    type: 'group',
    auth: authRole.dashboardRoles,
    icon: 'check_box',
    children: [
      {
        id: 'Digitized prescriptions',
        title: 'Digitized prescriptions',
        messageId: 'sidebar.digitized',
        type: 'item',
        auth: authRole.dashboardRoles,
        url: '/digitizedpres',
      },

      {
        id: 'Download Prescriptions',
        title: 'Download Prescriptions',
        messageId: 'sidebar.download',
        type: 'item',
        url: '/downloadPrescription',
      },
    ],
  },

  {
    id: 'searchpatientphar',
    title: 'searchpatientphar',
    messageId: 'sidebar.searchPat',
    type: 'single',
    icon: 'manage_search',
    auth: authRole.pharmacyRoles,
    url: '/pharmacy',
  },
  {
    id: 'dispensedList',
    title: 'dispensedList',
    messageId: 'sidebar.disList',
    type: 'single',
    icon: 'domain_verification',
    auth: authRole.pharmacyRoles,
    url: '/pharmacydispenced',
  },
  {
    id: 'itadmin',
    title: 'itadmin',
    messageId: 'sidebar.itadmin',
    type: 'single',
    icon: 'document_scanner',
    auth: authRole.itAdminRoles,
    url: '/ITAdminpage',
  },
  {
    id: 'searchpatientlab',
    title: 'searchpatientlab',
    messageId: 'sidebar.searchPat',
    type: 'single',
    icon: 'manage_search',
    auth: authRole.labRoles,
    url: '/labAssist',
  },
  {
    id: 'completedListlab',
    title: 'completedListlab',
    messageId: 'sidebar.comList',
    type: 'single',
    icon: 'domain_verification',
    auth: authRole.labRoles,
    url: '/labAssistComplete',
  },
  {
    id: 'searchpatient',
    title: 'searchpatient',
    messageId: 'sidebar.searchPat',
    type: 'single',
    icon: 'manage_search',
    auth: authRole.radiologyRoles,
    url: '/radioAssist',
  },
  {
    id: 'completedListrad',
    title: 'completedListrad',
    messageId: 'sidebar.comList',
    type: 'single',
    icon: 'domain_verification',
    auth: authRole.radiologyRoles,
    url: '/radioAssistComplete',
  },
  {
    id: 'pendingRequest',
    title: 'pendingRequest',
    messageId: 'sidebar.pendReq',
    type: 'single',
    icon: 'manage_search',
    auth: authRole.itadminRoles,
    url: '/ITAdminpage',
  },
  {
    id: 'completedList',
    title: 'completedList',
    messageId: 'sidebar.comReq',
    type: 'single',
    icon: 'domain_verification',
    auth: authRole.itadminRoles,
    url: '/ITAdminCompleted',
  },
  {
    id: 'dischargeCell',
    title: 'dischargeCell',
    messageId: 'sidebar.disCell',
    type: 'single',
    icon: 'auto_mode',
    auth: authRole.MTRoles,
    url: '/dischargeCell',
  },
  {
    id: 'dischargeCell',
    title: 'dischargeCell',
    messageId: 'sidebar.disCell',
    type: 'single',
    icon: 'auto_mode',
    url: '/dichargecellProcess',
    auth: authRole.MTRoles,
    hide:true
  },
];
export default routesConfig;
