/************
 * Developed by : Shiva Software Solutions
 * Date : 14-06-2021
 * Descriptions : Route for dahboard page
 ************/
import React from 'react';
import {authRole} from '../../shared/constants/AppConst';

export const mrdUserRouteConfig = [
  {
    auth: authRole.userItad,
    routes: [
      {
        path: '/ITAdminpage',
        component: React.lazy(() =>
          import('../mrdUserPage/mrdUser/PendingRequestpage'),
        )
      },
    ],
  },
  {
    auth: authRole.userItad,
    routes: [
      {
        path: '/ITAdminCompleted',
        component: React.lazy(() =>
          import('../mrdUserPage/mrdUser/AcceptedRequestpage'),
        )
      },
    ],
  },
];
