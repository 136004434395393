/************
 * Developed by : Shiva Software Solutions
 * Date : 14-06-2024
 * Descriptions : Route for lab assist page
 ************/
import React from 'react';
import {authRole} from '../../shared/constants/AppConst';

export const labAssistConfigs = [
  {
    auth: authRole.labUser,
    routes: [
      {
        path: '/labAssist',
        component: React.lazy(() =>
          import('../labAssistPage/labAssist/PrescriptionsPage'),
        ),
      },
    ],
  },
  {
    auth: authRole.labUser,
    routes: [
      {
        path: '/labAssistComplete',
        component: React.lazy(() =>
          import('../labAssistPage/labAssist/DispensedListPage'),
        ),
      },
    ],
  },
];
