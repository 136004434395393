import React, {useContext, useState, useEffect} from 'react';
import Avatar from '@material-ui/core/Avatar';
import {useAuthUser} from '../../utility/AppHooks';
import AppContext from '../../utility/AppContext';
import {Divider, makeStyles} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Box from '@material-ui/core/Box';
import Badge from '@material-ui/core/Badge';
import {orange} from '@material-ui/core/colors';
import {Fonts} from '../../../shared/constants/AppEnums';
import Hidden from '@material-ui/core/Hidden';
import ChangePasswordPopup from './ChangepasswordPopup';
import NotificationsIcon from '@material-ui/icons/Notifications';
import HourglassTopIcon from '@material-ui/icons/HourglassEmptyOutlined';
import EditIcon from '@material-ui/icons/EditRounded';
// import confirmation dialog
import ConfirmationDialog from './confirmationDialog';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {MRD_OPEN_NOTIFI} from '../../../shared/constants/ActionTypes';
import {
  storeOnlineOfflineStatus,
  CallNotifications,
} from '../../../redux/actions';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import {Typography} from '@material-ui/core';
// for style
const useStyles = makeStyles((theme) => {
  return {
    mar3: {
      marginRight: '12px',
    },
    bgColor: {
      color: (props) =>
        props.bgType === 'colored' ? 'white' : theme.palette.text.primary,
    },
    userRoot: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      justifyContent: 'center',
      position: 'relative',
    },
    avatar: {
      fontSize: 24,
      backgroundColor: orange[500],
    },
    autowidht: {
      width: 'auto',
      height: 'auto',
      marginLeft: 6,
    },
    userInfo: {
      width: 'calc(100% - 75px)',
    },
    pointer: {
      cursor: 'pointer',
    },
    userName: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      cursor: 'pointer',
      whiteSpace: 'nowrap',
      fontSize: 15,
      fontWeight: Fonts.MEDIUM,
      color: (props) =>
        props.bgType === 'colored' ? 'white' : theme.palette.text.primary,
    },

    outerBox: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      justifyContent: 'center',
    },
    mainUserBox: {
      position: 'absolute',
      right: '4px',
    },
    onlineStatus: {
      position: 'absolute',
      zIndex: 99,

      color: '#21d221',
      width: 18,
      height: 18,
      top: '-7%',
      left: '2%',
    },
    offlineStatus: {
      position: 'absolute',
      zIndex: 99,

      color: '#ff0000',
      width: 18,
      height: 18,
      top: '-7%',
      left: '2%',
    },
    menuitems: {
      '& .MuiMenu-paper': {
        marginTop: '1.8%',
      },
    },
  };
});

const HorUserInfo = ({bgType = 'colored'}) => {
  const [changePassword, setChangePasswordPopup] = useState(false);
  const mt_notifications = useSelector(
    ({dashboard}) => dashboard.MTnotifications,
  );
  const [notificationBadgeCount, setNotificationBadgeCount] = useState(
    mt_notifications?.length ? mt_notifications?.length : 0,
  );
  const [getStatus, setgetStatus] = React.useState('Online');
  const [statusChange, setStatusChange] = React.useState({
    onlineshow: {display: 'block'},
    onlinehide: {display: 'none'},
    offlineshow: {display: 'block'},
    offlinehide: {display: 'none'},
  });
  const [openNoti, setopenNoti] = useState(false);
  const {themeMode} = useContext(AppContext);
  const [openpopup, setopenpopup] = useState(false);
  const user = useAuthUser();
  const dispatch = useDispatch();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [notificationOpen, setNotificationOpen] = React.useState(false);
  const [interVel, setInterVel] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // changepassword open popup event
  const openChangepasswordPopup = () => {
    setAnchorEl(null);
    setChangePasswordPopup(true);
  };

  // logout open popup event
  const logoutClick = () => {
    setAnchorEl(null);
    setopenpopup(true);
  };
  const onGoToMyAccount = () => {
    setAnchorEl(null);
    history.push('/my-profile');
  };
  // online/offline change event
  const statusChanges = (val) => {
    console.log(user, 'userdata');
    let pushdata = {
      userId: '',
      userCode: user.credentialid,
      onlineStatus: val,
      loginType: 'client',
    };
    dispatch(storeOnlineOfflineStatus(pushdata));
    setgetStatus(val);
    setAnchorEl(null);
  };
  const classes = useStyles({themeMode, bgType});

  // for user click
  const handleNotificationClick = (event) => {
    if (mt_notifications?.length > 0) {
      dispatch({
        type: MRD_OPEN_NOTIFI,
        payload: !openNoti,
      });
      setopenNoti(!openNoti);
    }
  };

  const handleNotificationClose = () => {
    setNotificationOpen(null);
  };

  const MRDnotification = () => {
    let pushdata = {
      clientId: user.clientid || 0,
      credentialId: user.credentialid || 0,
      userType: 'MT',
    };
    dispatch(CallNotifications(pushdata));
  };
  useEffect(() => {
    if (user?.roleId == 9) {
      MRDnotification();
      const timer = setInterval(() => {
        MRDnotification();
        if (interVel) clearInterval(interVel);
      }, 1 * 60 * 1000);

      setInterVel(timer);
      return () => {
        clearInterval(timer);
      };
    }
  }, []);

  return (
    <Box py={2} pl={{xs: 2, sm: 3, md: 5}} className={classes.mainUserBox}>
      <Box className={classes.outerBox}>
        <Box
          className={classes.userRoot}
          display='flex'
          onClick={handleNotificationClick}>
          <Badge
            className={`${classes.badgeStyle} ${classes.mar3}`}
            badgeContent={
              mt_notifications?.length > 0 ? mt_notifications?.length : 0
            }
            color='secondary'>
            <NotificationsIcon className={classes.bgColor} />
          </Badge>
        </Box>
        <Box className={classes.userRoot} display='flex' onClick={handleClick}>
          {/* {user.photoURL ? (
          <Avatar className={classes.avatar} src={user.photoURL} />
        ) : (
          <Avatar className={classes.avatar}>{getUserAvatar()}</Avatar>
        )} */}

          <Avatar
            src='/assets/images/profile-user.png'
            className={classes.autowidht}
          />
          {getStatus === 'Online' ? (
            <FiberManualRecordIcon className={classes.onlineStatus} />
          ) : (
            <FiberManualRecordIcon className={classes.offlineStatus} />
          )}
          {/* <Notification message={[]} key='notification' /> */}
          <Hidden mdDown>
            <Box ml={3} className={classes.userName}>
              {/* display name check */}
              {user.displayName ? user.displayName : user.email}
              <Box fontSize={13} fontWeight={Fonts.LIGHT}>
                {user.userCategory}
              </Box>
            </Box>
          </Hidden>
        </Box>
      </Box>
      <Box className={classes.userInfo}>
        <Menu
          id='simple-menu'
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          disableAutoFocusItem
          autoFocus={false}>
          <div>
            <MenuItem
              onClick={() => statusChanges('Online')}
              style={
                getStatus === 'Online'
                  ? statusChange.onlinehide
                  : statusChange.onlineshow
              }>
              {' '}
              <FiberManualRecordIcon className={classes.onlineStatus} />
              Online
            </MenuItem>
            <MenuItem
              onClick={() => statusChanges('Offline')}
              style={
                getStatus === 'Offline'
                  ? statusChange.offlinehide
                  : statusChange.offlineshow
              }>
              {' '}
              <FiberManualRecordIcon className={classes.offlineStatus} />
              Offline
            </MenuItem>
            <Divider></Divider>

            <MenuItem
              onClose={handleClose}
              onClick={() => openChangepasswordPopup()}>
              Change Password
            </MenuItem>
            <MenuItem
              onClick={() => {
                logoutClick();
              }}>
              Logout
            </MenuItem>
          </div>
        </Menu>
        <ChangePasswordPopup
          open={changePassword}
          onDeny={setChangePasswordPopup}
          title='Change Password'
          //  dialogTitle={<IntlMessages id='Change Password' />}
        />
        <ConfirmationDialog opendialog={openpopup} onDeny={setopenpopup} />
      </Box>

      {/* Notification Popup  */}
      <Menu
        id='simple-menu'
        anchorEl={notificationOpen}
        keepMounted
        autoFocus={false}
        disableAutoFocusItem
        className={classes.menuitems}
        open={Boolean(notificationOpen)}
        onClose={handleNotificationClose}>
        {mt_notifications && Object.keys(mt_notifications)?.length > 0 && (
          <div>
            <div style={{padding: '8px', background: '#0a8fdc'}}>
              <Typography
                variant='subtitle1'
                style={{fontWeight: 'bold', color: 'white'}}>
                Notification
              </Typography>
            </div>
            {parseInt(mt_notifications?.pendingCount) > 0 && (
              <MenuItem>
                <span
                  style={{
                    fontSize: '14px',
                    color: 'black',
                    alignItems: 'center',
                    display: 'flex',
                  }}>
                  <>
                    <HourglassTopIcon /> {mt_notifications?.pendingCount}{' '}
                    Request to pending to proceed
                  </>
                </span>
              </MenuItem>
            )}
            {parseInt(mt_notifications?.editRequiredCount) > 0 && (
              <MenuItem>
                <span
                  style={{
                    fontSize: '14px',
                    color: 'black',
                    alignItems: 'center',
                    display: 'flex',
                  }}>
                  {/* <span style={{fontWeight: 600}}>MRD001 </span>-{' '} */}
                  <>
                    <EditIcon />
                    {mt_notifications?.editRequiredCount} Edit Required
                  </>
                </span>
              </MenuItem>
            )}
          </div>
        )}
      </Menu>
    </Box>
  );
};

export default HorUserInfo;
