/************
 * Developed by : Shiva Software Solutions
 * Date : 14-06-2021
 * Descriptions : Route for dahboard page
 ************/
import React from 'react';
import {authRole} from '../../shared/constants/AppConst';

export const dashBoardConfigs = [
  {
    auth: authRole.adminUsers,
    routes: [
      {
        path: '/dashboard',
        component: React.lazy(() =>
          import('../dashboard/DashboardPage/DashboardChart'),
        ),
      },
    ],
  },
  {
    auth: authRole.adminUsers,
    routes: [
      {
        path: '/billing',
        component: React.lazy(() =>
          import('../dashboard/DashboardPage/BillingTab'),
        ),
      },
    ],
  },
  {
    auth: authRole.adminUsers,
    routes: [
      {
        path: '/digitizedpres',
        component: React.lazy(() =>
          import('../dashboard/DashboardPage/VendorDashboard'),
        ),
      },
    ],
  },
  {
    auth: authRole.adminUsers,
    routes: [
      {
        path: '/downloadPrescription',
        component: React.lazy(() =>
          import('../dashboard/DashboardPage/DownloadsTab'),
        ),
      },
    ],
  },
  {
    auth: authRole.adminUsers,
    routes: [
      {
        path: '/billing',
        component: React.lazy(() =>
          import('../dashboard/DashboardPage/BillingTab'),
        ),
      },
    ],
  },
  {
    auth: authRole.camDashusers,
    routes: [
      {
        path: '/cameraDashboard',
        component: React.lazy(() =>
          import('../dashboard/DashboardPage/cameraDashboard'),
        ),
      },
    ],
  },
  {
    auth: authRole.adminUsers,
    routes: [
      {
        path: '/vaccineReport',
        component: React.lazy(() =>
          import('../dashboard/DashboardPage/VaccineDashboard'),
        ),
      },
    ],
  },
  {
    auth: authRole.adminUsers,
    routes: [
      {
        path: '/mrdReport',
        component: React.lazy(() =>
          import('../dashboard/DashboardPage/pendingMrd'),
        ),
      },
    ],
  },
  {
    auth: authRole.adminUsers,
    routes: [
      {
        path: '/mrdLabReport',
        component: React.lazy(() =>
          import('../dashboard/DashboardPage/mrdLabReport'),
        ),
      },
    ],
  },
  {
    auth: authRole.adminUsers,
    routes: [
      {
        path: '/myDoctors',
        component: React.lazy(() =>
          import('../dashboard/DashboardPage/DoctorimportTab'),
        ),
      },
    ],
  },
];
