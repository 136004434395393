/************ 
 * Developed by : Shiva Software Solutions
 * Date : 30-01-2023
 * Descriptions : Route for doctor upload page
 ************/
import React from 'react';
import {authRole} from '../../shared/constants/AppConst';

export const uploadPageConfig = [
  {
    auth: authRole.adminUsers,
    routes: [
      {
        path: '/doctorImport',
        component: React.lazy(() => import('./DoctorImportPage')),
      },
    ],
  },
  
];

 