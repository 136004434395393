import React from 'react';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  SEARCH_DISPENSES_PENDING,
  SEARCH_TOTAL_COUNT,
  ERX_IMAGES_ARRAY,
  ERX_DISPENSED_IMAGES_ARRAY,
  SAVE_DISPENSES_MSG,
  SEARCH_LIST_RESP,
  SEARCH_DISPENSED_LIST,
  SEARCH_DISPENSE_LIST_RESP,
  MRD_LABRE_EXCEL,
  MRD_LABRE_LIST,
  MRD_LABRE_COUNT,
  MRD_PAGE_COUNT,
} from '../../shared/constants/ActionTypes';
import IntlMessages from '../../@crema/utility/IntlMessages';
import jwtAxios from '../../@crema/services/auth/jwt-auth/jwt-api';
import {fetchError, onWriteCloudWatchLog} from './Common';
//AWS cloud watch name
var streamname = 'Redux_Actions';
var filename = 'MrdUser.js';
export const getMrdLabReport = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios.post('v1/mrdUser/getMRDLabReportJwt', value).then((data) => {
        try {
          let passData = {
            jwtToken: data.data.body['token'],
          };
          jwtAxios
            .post('v1/mrdUser/getMRDLabReport', passData)
            .then((data) => {
              dispatch({type: FETCH_SUCCESS});
              if (value?.tag == 'excel') {
                dispatch({
                  type: MRD_LABRE_EXCEL,
                  payload: data?.data?.body?.list ? data.data.body?.list : [],
                });
              } else {
                dispatch({
                  type: MRD_LABRE_LIST,
                  payload: data?.data?.body?.list ? data.data.body?.list : [],
                });
                dispatch({
                  type: MRD_LABRE_COUNT,
                  payload: data.data.body?.count ? data.data.body?.count : 0,
                });
                dispatch({
                  type: MRD_PAGE_COUNT,
                  payload: data.data.body?.pagecount
                    ? data.data.body.pagecount
                    : 0,
                });
              }
            })
            .catch((error) => {
              dispatch({type: FETCH_ERROR, payload: error.message});
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'getMrdLabReport',
                  '0',
                  error,
                ),
              );
            });
        } catch (err) {
          dispatch(fetchError(err.response.message));
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'getMrdLabReport',
              '0',
              err,
            ),
          );
        }
      });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'getMrdLabReport', '0', err),
      );
    }
  };
};
